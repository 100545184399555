import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

          
<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2823 1686 c510 -137 862 -503 983 -1021 29 -124 27 -445 -4 -575 -55
-227 -163 -431 -317 -597 -186 -201 -388 -321 -660 -394 -114 -31 -129 -32
-310 -33 -151 -1 -208 3 -275 18 -123 27 -222 62 -340 121 -358 179 -610 505
-701 904 -26 116 -36 334 -19 453 44 326 210 633 452 836 151 127 302 211 477
266 149 47 219 56 426 52 167 -3 202 -6 288 -30z m-861 -2783 c16 -14 18 -33
18 -165 l0 -148 -39 0 -39 0 5 55 c5 54 5 55 -21 55 -25 0 -26 -2 -26 -55 l0
-55 -41 0 -40 0 3 148 c2 108 7 154 17 165 19 24 137 24 163 0z m932 -5 c9
-12 16 -33 16 -45 0 -20 -5 -23 -35 -23 -25 0 -35 4 -35 16 0 10 -7 14 -22 12
-18 -2 -23 -10 -23 -33 0 -27 4 -30 44 -37 65 -11 71 -20 71 -103 0 -89 -9
-99 -92 -100 -51 0 -62 3 -79 24 -11 14 -19 36 -17 50 3 22 8 26 38 26 28 0
35 -4 35 -20 0 -13 8 -21 23 -23 20 -3 22 1 22 37 l0 41 -44 0 c-59 0 -71 17
-71 100 0 89 10 100 91 100 54 0 65 -3 78 -22z m-1224 -118 l0 -130 35 0 c32
0 35 -2 35 -30 l0 -30 -75 0 -75 0 0 160 0 160 40 0 40 0 0 -130z m544 114
c19 -18 22 -114 5 -131 -8 -8 -8 -13 0 -17 6 -4 11 -39 11 -82 l0 -74 -35 0
-35 0 -1 50 c-2 73 -2 74 -21 78 -16 3 -18 -5 -18 -62 l0 -66 -40 0 -40 0 0
160 0 160 79 0 c59 0 84 -4 95 -16z m226 -14 c0 -28 -2 -30 -40 -30 -39 0 -40
-1 -40 -35 0 -33 2 -35 35 -35 32 0 35 -2 35 -30 0 -28 -3 -30 -35 -30 -33 0
-35 -2 -35 -35 0 -34 1 -35 40 -35 38 0 40 -2 40 -30 l0 -30 -75 0 -75 0 0
160 0 160 75 0 75 0 0 -30z m122 -17 l15 -48 14 48 c13 45 15 47 50 47 l37 0
-25 -81 -25 -81 26 -69 c14 -39 26 -75 26 -80 0 -6 -17 -9 -37 -7 -34 3 -37 6
-53 53 l-17 50 -13 -52 c-14 -53 -14 -53 -53 -53 -27 0 -38 4 -34 13 2 6 15
44 27 83 l23 71 -22 63 c-28 85 -27 90 12 90 31 0 35 -3 49 -47z m478 -93 c0
-133 1 -140 20 -140 19 0 20 7 20 140 l0 140 40 0 40 0 0 -143 c0 -181 -3
-187 -99 -187 -44 0 -72 5 -79 13 -6 7 -12 76 -14 165 l-3 152 38 0 37 0 0
-140z m340 130 c25 -13 34 -62 20 -106 -9 -26 -9 -39 -1 -47 6 -6 11 -36 11
-66 0 -80 -13 -91 -117 -91 l-83 0 0 160 0 160 76 0 c41 0 84 -4 94 -10z
m-2000 -450 c0 -64 -3 -80 -15 -80 -12 0 -15 16 -15 80 0 64 3 80 15 80 12 0
15 -16 15 -80z m1000 65 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15
15 8 0 15 -7 15 -15z m360 0 c0 -8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12
33 6 33 -9z m-1520 -20 c0 -8 -9 -15 -20 -15 -18 0 -20 -7 -20 -56 0 -47 -3
-55 -17 -52 -13 2 -18 14 -20 38 -1 19 -2 42 -2 51 -1 9 -10 19 -21 22 -43 11
-19 27 40 27 47 0 60 -3 60 -15z m1450 1 c0 -8 6 -16 13 -19 10 -4 10 -7 0
-18 -15 -16 -17 -55 -3 -64 18 -11 10 -26 -12 -23 -18 2 -24 11 -26 38 -2 19
-9 41 -15 49 -9 11 -9 15 1 18 6 3 12 11 12 19 0 8 7 14 15 14 8 0 15 -6 15
-14z m-1352 -28 c7 -7 12 -20 12 -30 0 -14 -8 -18 -36 -18 -23 0 -33 -4 -28
-11 3 -6 16 -8 29 -5 27 7 39 -3 24 -21 -26 -30 -89 -2 -89 40 0 44 59 74 88
45z m170 0 c7 -7 12 -20 12 -30 0 -13 -8 -18 -30 -18 -16 0 -30 -5 -30 -11 0
-7 10 -9 25 -7 30 6 40 -5 20 -22 -22 -18 -58 -8 -74 21 -13 24 -13 29 2 53
19 28 54 35 75 14z m122 -3 c14 -17 0 -26 -35 -22 -14 1 -20 -4 -20 -18 0 -17
6 -19 33 -17 33 3 40 -6 20 -26 -19 -19 -66 -15 -78 7 -14 26 -13 64 2 79 17
17 63 15 78 -3z m110 0 c16 -20 16 -61 0 -80 -19 -23 -62 -14 -79 16 -13 24
-13 29 2 53 20 29 57 35 77 11z m178 3 c18 -15 16 -98 -3 -98 -11 0 -15 11
-15 41 0 52 -24 48 -28 -4 -5 -53 -32 -50 -32 3 0 29 -4 40 -15 40 -11 0 -15
-11 -15 -40 0 -29 -4 -40 -15 -40 -12 0 -15 13 -15 55 l0 55 63 -1 c34 -1 68
-6 75 -11z m183 -15 c5 -13 9 -38 9 -54 0 -22 -5 -29 -20 -29 -17 0 -20 7 -20
40 0 22 -4 40 -10 40 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40 -17 0 -20 7
-20 40 0 22 -4 40 -10 40 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40 -18 0
-20 7 -20 55 l0 55 71 -2 c65 -1 71 -3 80 -25z m61 -10 c4 -52 28 -56 28 -4 0
34 3 41 20 41 18 0 20 -7 20 -55 l0 -55 -38 0 c-49 0 -62 14 -62 67 0 56 27
61 32 6z m178 18 c5 -11 10 -36 10 -55 0 -29 -4 -36 -20 -36 -17 0 -20 7 -20
41 0 28 -4 39 -12 37 -13 -5 -15 -9 -17 -53 0 -15 -7 -25 -16 -25 -12 0 -15
13 -15 55 l0 55 40 0 c29 0 42 -5 50 -19z m60 -36 c0 -42 -3 -55 -15 -55 -12
0 -15 13 -15 55 0 42 3 55 15 55 12 0 15 -13 15 -55z m108 43 c21 -21 13 -31
-18 -25 -32 7 -43 -2 -34 -27 4 -11 14 -14 35 -9 30 6 37 -5 17 -25 -30 -30
-88 -2 -88 43 0 45 58 73 88 43z m112 -14 c6 -14 10 -39 10 -55 0 -29 -1 -30
-42 -27 -40 3 -43 5 -43 33 0 23 6 31 25 36 l25 7 -27 1 c-49 2 -31 28 20 30
15 1 26 -7 32 -25z m140 -29 c0 -62 -23 -78 -34 -24 -8 40 2 79 20 79 10 0 14
-15 14 -55z m112 30 c18 -39 0 -79 -37 -83 -34 -4 -55 18 -55 58 0 34 17 50
52 50 21 0 32 -7 40 -25z m116 13 c18 -18 16 -98 -2 -98 -10 0 -16 13 -18 37
-4 52 -28 56 -28 4 0 -34 -3 -41 -20 -41 -18 0 -20 7 -20 55 l0 55 38 0 c21 0
43 -5 50 -12z m162 -3 c14 -17 0 -26 -35 -22 -14 1 -20 -4 -20 -18 0 -17 6
-19 33 -17 33 3 40 -6 20 -26 -19 -19 -66 -15 -78 7 -14 26 -13 64 2 79 17 17
63 15 78 -3z m117 -11 c15 -24 15 -29 2 -53 -11 -19 -23 -26 -44 -26 -21 0
-33 7 -44 26 -13 24 -13 29 2 53 11 16 27 26 42 26 15 0 31 -10 42 -26z m173
5 c16 -29 12 -89 -5 -89 -9 0 -15 10 -16 25 -2 44 -4 48 -16 53 -9 2 -13 -9
-13 -37 0 -34 -3 -41 -19 -41 -14 0 -19 6 -16 19 5 25 -5 61 -16 61 -5 0 -9
-18 -9 -41 0 -31 -4 -40 -16 -37 -8 2 -15 6 -15 11 1 4 1 27 1 52 l0 44 65 -1
c52 -1 68 -5 75 -19z m118 9 c7 -7 12 -26 12 -43 0 -35 -15 -55 -42 -55 -11 0
-18 -7 -18 -20 0 -13 -7 -20 -20 -20 -18 0 -20 7 -20 75 l0 75 38 0 c21 0 43
-5 50 -12z m110 0 c7 -7 12 -31 12 -55 0 -41 -1 -43 -29 -43 -42 0 -61 9 -61
30 0 20 16 36 45 43 13 3 8 5 -14 6 -29 1 -32 3 -21 16 15 18 51 20 68 3z
m120 0 c18 -18 16 -98 -2 -98 -10 0 -16 13 -18 37 -4 52 -28 56 -28 4 0 -34
-3 -41 -20 -41 -18 0 -20 7 -20 55 l0 55 38 0 c21 0 43 -5 50 -12z m68 -20 l7
-33 10 33 c6 20 16 32 28 32 17 0 17 -3 -3 -62 -24 -75 -51 -109 -70 -89 -11
10 -9 15 6 26 19 14 19 16 -2 70 -21 53 -21 55 -2 55 13 0 21 -10 26 -32z"/>
<path d="M2360 4193 c-68 -7 -136 -23 -207 -50 l-72 -27 105 -18 106 -18 46
40 c26 21 67 48 92 59 l45 21 -40 -2 c-22 -1 -56 -3 -75 -5z"/>
<path d="M2483 4175 c-23 -7 -62 -24 -85 -38 -42 -24 -86 -64 -77 -70 17 -12
122 -47 156 -52 33 -5 55 -1 110 23 67 29 138 53 198 67 l30 7 -45 20 c-56 25
-185 58 -219 57 -14 0 -45 -6 -68 -14z"/>
<path d="M2785 4150 c65 -28 133 -43 95 -21 -20 12 -140 51 -154 51 -6 0 20
-14 59 -30z"/>
<path d="M2045 4081 c-35 -6 -141 -68 -132 -78 2 -1 35 4 73 12 38 8 118 18
177 21 59 4 110 11 112 15 11 17 -169 40 -230 30z"/>
<path d="M2737 4071 c-72 -20 -177 -62 -177 -72 0 -3 22 -11 50 -17 27 -6 102
-32 165 -57 l116 -46 36 32 c20 17 52 38 72 46 l35 15 -28 22 c-65 49 -151 96
-175 95 -14 0 -56 -8 -94 -18z"/>
<path d="M2960 4054 c30 -20 65 -45 76 -56 14 -12 32 -18 50 -16 27 3 24 7
-50 56 -44 29 -90 52 -105 52 -22 0 -19 -5 29 -36z"/>
<path d="M3032 4075 c23 -23 154 -115 164 -115 7 0 21 -3 31 -7 17 -6 17 -5
-1 15 -27 31 -104 79 -156 97 -24 8 -41 13 -38 10z"/>
<path d="M1859 4027 c-102 -58 -151 -110 -72 -75 52 23 163 108 141 108 -7 0
-38 -15 -69 -33z"/>
<path d="M2097 4009 c-42 -4 -114 -18 -160 -30 -72 -19 -88 -28 -128 -69 -62
-63 -169 -213 -169 -236 0 -2 16 -4 35 -4 58 0 186 -29 245 -56 78 -36 107
-28 180 49 33 34 59 71 60 83 0 35 38 170 59 215 12 24 21 46 21 51 0 9 -34 9
-143 -3z"/>
<path d="M2235 3945 c-33 -71 -62 -171 -52 -182 4 -3 34 20 69 52 35 31 92 77
128 102 36 25 69 49 74 54 16 14 -39 33 -112 39 l-73 6 -34 -71z"/>
<path d="M2534 3968 c75 -51 181 -133 232 -181 34 -31 64 -57 68 -57 3 0 6 9
6 20 0 12 9 40 20 62 11 23 20 43 20 44 0 0 -28 14 -62 29 -127 57 -336 118
-284 83z"/>
<path d="M2414 3917 c-34 -23 -102 -77 -153 -121 -91 -78 -91 -79 -91 -126 l0
-46 53 7 c101 15 400 22 497 10 101 -11 110 -8 110 36 0 29 -132 151 -244 225
-48 32 -93 58 -100 57 -6 0 -39 -19 -72 -42z"/>
<path d="M3000 3939 c-31 -12 -90 -57 -90 -68 0 -5 14 -15 32 -22 17 -7 81
-46 140 -86 l109 -73 35 18 36 18 -43 55 c-69 89 -151 169 -174 168 -11 0 -31
-5 -45 -10z"/>
<path d="M3160 3883 c34 -38 76 -87 93 -111 24 -33 35 -41 51 -36 50 15 49 19
-47 117 -81 83 -99 97 -127 97 l-32 0 62 -67z"/>
<path d="M3282 3861 c34 -38 65 -67 66 -66 10 9 -55 100 -82 116 -53 33 -50
21 16 -50z"/>
<path d="M1729 3900 c-42 -22 -78 -78 -108 -168 -28 -82 -26 -87 8 -25 16 29
57 89 90 133 34 44 58 80 54 80 -5 -1 -24 -10 -44 -20z"/>
<path d="M3350 3851 c0 -7 9 -30 20 -51 17 -33 26 -40 50 -40 17 0 30 4 30 9
0 5 -22 28 -50 51 -27 23 -50 37 -50 31z"/>
<path d="M2871 3794 c-12 -30 -21 -62 -21 -72 0 -9 20 -38 44 -63 63 -66 92
-69 188 -22 42 21 77 41 78 44 0 7 -173 120 -227 147 l-42 22 -20 -56z"/>
<path d="M1557 3780 c-61 -56 -167 -184 -167 -203 0 -4 18 5 41 20 22 14 63
34 91 44 51 17 52 18 78 91 15 40 30 81 35 91 13 31 -11 18 -78 -43z"/>
<path d="M3418 3742 c-10 -2 -18 -5 -18 -8 0 -2 24 -38 53 -80 l52 -75 12 28
c19 45 16 87 -9 110 -24 21 -60 31 -90 25z"/>
<path d="M3311 3721 c-11 -7 -12 -13 -3 -24 6 -7 34 -56 62 -108 l51 -94 -6
50 c-7 65 -25 145 -37 168 -9 18 -44 22 -67 8z"/>
<path d="M3232 3694 l-32 -14 80 -71 c44 -39 84 -78 90 -87 6 -11 10 -12 10
-4 0 19 -100 193 -110 191 -3 0 -20 -7 -38 -15z"/>
<path d="M2111 3649 l-34 -41 34 7 c41 9 37 4 44 43 7 42 -3 40 -44 -9z"/>
<path d="M2840 3657 c0 -20 6 -28 25 -33 37 -9 39 -1 5 31 l-30 29 0 -27z"/>
<path d="M3095 3628 l-60 -31 130 -42 c72 -23 151 -52 175 -65 l45 -22 -30 33
c-73 82 -161 159 -182 159 -10 -1 -45 -15 -78 -32z"/>
<path d="M3537 3608 l-16 -52 29 -52 c28 -48 34 -52 76 -59 25 -3 48 -4 51 -1
6 6 -59 127 -96 178 l-27 37 -17 -51z"/>
<path d="M1602 3603 c-21 -50 -37 -163 -22 -163 5 0 40 15 79 34 38 18 117 49
175 67 58 19 106 37 106 40 0 10 -109 48 -169 59 -29 6 -75 10 -101 10 -46 0
-47 -1 -68 -47z"/>
<path d="M3426 3615 c3 -22 9 -65 13 -95 l7 -55 22 43 c21 39 21 44 8 70 -9
15 -25 38 -36 52 l-20 25 6 -40z"/>
<path d="M1525 3621 c-99 -40 -160 -95 -194 -171 -22 -50 -51 -182 -51 -230
l0 -32 49 54 c27 29 76 73 108 98 59 44 60 46 97 155 21 61 37 118 35 127 -4
14 -7 14 -44 -1z"/>
<path d="M2251 3617 l-84 -9 7 -75 c7 -70 9 -76 44 -105 20 -17 78 -72 127
-123 l90 -91 122 90 c68 50 158 114 201 141 l78 50 -4 55 c-3 30 -6 57 -9 60
-18 19 -422 25 -572 7z"/>
<path d="M2841 3588 c1 -12 4 -35 8 -50 l6 -27 47 27 c39 23 44 30 34 43 -6 9
-30 20 -54 24 -40 6 -42 5 -41 -17z"/>
<path d="M2088 3593 c-24 -4 -57 -29 -47 -37 7 -5 94 -62 107 -70 10 -6 13 5
11 53 -2 66 0 65 -71 54z"/>
<path d="M2993 3572 c-9 -6 3 -29 41 -79 29 -39 59 -81 67 -92 l14 -21 137 28
c76 15 138 30 138 33 0 15 -337 139 -376 139 -5 0 -14 -4 -21 -8z"/>
<path d="M1855 3529 c-63 -21 -150 -56 -193 -77 l-77 -38 1 -95 c1 -56 11
-137 25 -202 20 -96 26 -110 47 -118 14 -5 26 -8 27 -7 2 2 24 50 51 108 71
157 156 301 251 426 23 31 23 32 4 37 -11 3 -72 -12 -136 -34z"/>
<path d="M2908 3522 c-27 -15 -48 -34 -48 -43 0 -15 38 -150 45 -157 3 -3 156
38 173 47 9 5 -6 32 -49 94 -34 48 -65 87 -68 87 -3 0 -27 -13 -53 -28z"/>
<path d="M1997 3508 c-99 -131 -262 -426 -282 -508 -5 -23 2 -31 60 -73 l65
-47 128 64 c70 35 165 80 212 101 47 21 86 39 87 39 2 1 -7 27 -18 57 -12 30
-36 113 -54 184 l-32 130 -63 43 c-35 23 -67 42 -71 42 -5 0 -19 -15 -32 -32z"/>
<path d="M3478 3486 l-26 -43 47 5 c25 2 48 6 50 8 3 3 -38 74 -42 74 -1 0
-14 -20 -29 -44z"/>
<path d="M2775 3438 c-129 -84 -325 -232 -325 -245 0 -28 29 -22 190 36 91 32
184 65 208 72 33 9 41 16 36 28 -3 9 -14 45 -25 80 -10 35 -20 65 -21 66 -2 1
-30 -15 -63 -37z"/>
<path d="M1536 3447 c-8 -30 -7 -37 3 -37 11 0 21 49 11 55 -4 3 -10 -5 -14
-18z"/>
<path d="M2190 3413 c0 -25 46 -196 71 -265 l21 -57 68 56 69 56 -49 57 c-75
86 -180 176 -180 153z"/>
<path d="M3484 3417 c10 -7 45 -31 78 -55 34 -25 55 -35 50 -25 -5 10 -17 35
-27 56 -17 36 -19 37 -69 36 -43 0 -48 -2 -32 -12z"/>
<path d="M3600 3421 c0 -5 16 -42 36 -81 38 -77 37 -77 69 -18 23 41 3 85 -43
98 -46 12 -62 13 -62 1z"/>
<path d="M3254 3396 c-62 -13 -116 -25 -122 -27 -7 -3 72 -157 92 -176 4 -5
102 111 158 187 l30 40 -24 -1 c-12 0 -73 -11 -134 -23z"/>
<path d="M1528 3378 c-19 -16 -47 -142 -55 -254 l-6 -81 69 -11 c37 -6 69 -10
71 -8 2 1 -6 44 -17 94 -11 51 -23 133 -27 182 -3 50 -9 90 -13 90 -3 0 -13
-6 -22 -12z"/>
<path d="M3345 3304 c-38 -47 -80 -98 -91 -113 l-21 -26 39 -82 38 -82 34 19
c31 19 34 25 50 115 17 94 33 255 25 255 -2 0 -35 -39 -74 -86z"/>
<path d="M3504 3358 c9 -13 35 -54 57 -91 l42 -68 26 25 25 25 -24 26 c-30 32
-124 105 -135 105 -4 0 0 -10 9 -22z"/>
<path d="M3441 3335 c-1 -37 -24 -201 -36 -253 l-6 -24 31 18 c17 10 42 28 56
39 l26 20 -21 80 c-11 44 -27 98 -35 120 l-15 40 0 -40z"/>
<path d="M3008 3333 c-49 -14 -88 -31 -88 -37 0 -17 50 -110 104 -195 l48 -74
70 71 71 70 -54 96 c-30 53 -56 95 -59 95 -3 -1 -44 -12 -92 -26z"/>
<path d="M3501 3261 c16 -57 31 -106 33 -108 2 -2 14 5 27 15 l23 19 -41 69
c-70 117 -74 117 -42 5z"/>
<path d="M1429 3306 c-30 -24 -76 -68 -102 -98 -46 -53 -47 -57 -47 -120 0
-36 3 -79 6 -96 l6 -31 40 30 c22 15 56 31 74 35 39 7 40 10 49 126 4 43 13
103 20 135 7 32 13 59 11 60 -1 1 -27 -17 -57 -41z"/>
<path d="M3704 3286 l-20 -32 38 -48 c26 -32 38 -42 38 -29 0 20 -26 132 -31
137 -2 2 -13 -11 -25 -28z"/>
<path d="M2825 3278 c-85 -29 -339 -120 -343 -123 -2 -1 26 -45 61 -97 35 -51
90 -148 123 -215 32 -68 61 -122 64 -123 6 0 300 260 318 283 11 13 7 23 -27
68 -22 29 -59 92 -83 140 -24 49 -45 88 -48 88 -3 0 -32 -10 -65 -21z"/>
<path d="M1247 3235 c-4 -22 -6 -56 -6 -75 1 -25 4 -18 10 25 10 68 7 111 -4
50z"/>
<path d="M3632 3207 l-22 -24 34 -74 c19 -41 44 -107 56 -148 23 -75 40 -81
40 -15 0 33 -53 222 -74 261 l-12 24 -22 -24z"/>
<path d="M3704 3175 c3 -11 15 -56 28 -100 l22 -80 9 42 c8 43 -8 89 -48 138
l-16 20 5 -20z"/>
<path d="M2380 3150 c-19 -16 -32 -30 -29 -30 19 -1 99 43 94 51 -10 16 -29
10 -65 -21z"/>
<path d="M3559 3146 c-25 -21 -26 -27 -18 -67 5 -24 12 -106 15 -181 4 -76 9
-138 11 -138 12 0 91 45 111 63 27 25 28 47 2 129 -22 72 -83 218 -90 218 -3
-1 -17 -11 -31 -24z"/>
<path d="M3152 3087 c-34 -34 -62 -66 -62 -70 0 -13 72 -87 85 -87 16 0 103
48 111 61 5 9 -60 159 -68 159 -2 0 -32 -28 -66 -63z"/>
<path d="M2368 3108 c-43 -17 -78 -34 -78 -38 0 -4 26 -59 58 -123 65 -127
180 -307 196 -307 25 0 166 63 166 75 0 48 -219 417 -251 422 -8 2 -49 -11
-91 -29z"/>
<path d="M3449 3070 c-52 -36 -57 -43 -76 -110 -21 -70 -21 -73 -4 -123 15
-44 23 -54 52 -64 19 -7 54 -12 78 -12 l44 -1 -6 133 c-7 142 -16 217 -26 216
-3 0 -31 -17 -62 -39z"/>
<path d="M1247 3087 c-9 -22 -7 -130 2 -172 l7 -30 9 25 c5 14 7 34 6 45 -2
11 -6 49 -10 85 -4 43 -9 59 -14 47z"/>
<path d="M2179 2982 c-48 -42 -120 -108 -162 -146 l-75 -69 55 -81 c31 -44 67
-106 81 -138 16 -37 31 -58 41 -58 22 0 383 123 400 136 2 2 -28 52 -67 111
-38 59 -94 156 -123 216 -30 59 -56 107 -59 107 -3 0 -44 -35 -91 -78z"/>
<path d="M2120 3001 c-47 -21 -127 -59 -179 -85 l-94 -48 39 -41 c22 -23 40
-44 41 -46 2 -2 49 38 105 90 57 52 122 111 146 132 23 20 39 37 35 37 -5 0
-46 -18 -93 -39z"/>
<path d="M1478 3022 c-22 -4 -23 -57 -8 -211 16 -153 14 -151 85 -99 57 42 61
48 78 112 17 63 17 70 1 121 -10 30 -25 57 -33 60 -25 10 -104 21 -123 17z"/>
<path d="M1384 3000 c-23 -12 -51 -31 -64 -45 -21 -22 -22 -27 -10 -77 13 -57
75 -222 94 -249 10 -15 14 -15 39 7 27 23 27 25 18 86 -6 35 -13 116 -17 181
-4 64 -10 117 -13 117 -3 0 -24 -9 -47 -20z"/>
<path d="M3776 2972 c-6 -47 -5 -61 10 -147 l7 -40 7 30 c15 62 8 205 -10 205
-4 0 -10 -22 -14 -48z"/>
<path d="M3336 2997 c-13 -10 -16 -20 -12 -35 10 -31 23 -27 31 9 8 37 4 43
-19 26z"/>
<path d="M3010 2946 c-30 -29 -95 -87 -143 -130 -48 -42 -85 -76 -82 -76 9 0
201 88 272 125 107 55 103 49 58 95 -21 22 -42 40 -45 40 -3 0 -30 -24 -60
-54z"/>
<path d="M1205 2980 c-12 -20 -11 -178 2 -210 9 -22 11 -17 17 40 7 81 -5 192
-19 170z"/>
<path d="M1650 2960 c0 -26 17 -34 23 -11 3 12 -1 22 -9 25 -9 3 -14 -2 -14
-14z"/>
<path d="M3238 2942 c-26 -15 -48 -30 -48 -33 0 -3 27 -25 60 -48 l59 -42 11
38 c9 31 8 45 -4 76 -9 20 -20 37 -24 36 -4 0 -28 -12 -54 -27z"/>
<path d="M1682 2924 c-8 -28 -7 -43 5 -72 8 -20 17 -39 18 -40 5 -6 105 52
105 60 0 8 -67 61 -96 76 -19 10 -22 7 -32 -24z"/>
<path d="M3125 2882 c-16 -10 -106 -54 -200 -97 -93 -43 -174 -82 -178 -86 -5
-4 5 -49 23 -100 17 -52 38 -133 48 -181 l17 -87 69 -46 c37 -25 71 -45 75
-45 9 0 158 226 204 310 38 70 120 251 115 255 -15 15 -130 95 -136 95 -4 0
-20 -8 -37 -18z"/>
<path d="M1265 2861 c-7 -33 19 -148 58 -251 l23 -61 22 24 23 24 -35 79 c-18
43 -42 106 -52 139 -22 75 -30 85 -39 46z"/>
<path d="M3736 2865 c-3 -8 0 -53 6 -101 9 -67 14 -81 20 -63 5 13 6 58 2 101
-6 75 -15 95 -28 63z"/>
<path d="M1651 2821 c-14 -50 -12 -56 14 -37 16 11 17 18 8 47 l-10 34 -12
-44z"/>
<path d="M1763 2820 l-52 -30 35 -77 c19 -42 36 -78 37 -80 2 -1 33 28 70 65
l66 67 -40 43 c-22 23 -46 42 -52 42 -7 0 -36 -14 -64 -30z"/>
<path d="M3335 2837 c-4 -10 -3 -23 4 -30 16 -16 23 -3 12 24 -8 22 -10 22
-16 6z"/>
<path d="M3675 2795 c-17 -13 -49 -30 -72 -37 -24 -7 -43 -17 -43 -23 0 -31
-31 -245 -39 -273 -8 -26 -7 -31 4 -25 32 18 130 109 166 156 l40 50 -7 71
c-10 106 -13 111 -49 81z"/>
<path d="M3286 2723 c-49 -115 -124 -253 -207 -376 -43 -65 -79 -120 -79 -122
0 -25 184 32 329 101 l94 44 -6 103 c-4 78 -40 292 -51 304 0 1 -12 4 -26 8
-23 5 -27 1 -54 -62z"/>
<path d="M1242 2724 c3 -50 10 -69 37 -105 19 -24 30 -35 26 -24 -4 11 -19 56
-32 99 -13 44 -26 82 -29 85 -4 3 -4 -21 -2 -55z"/>
<path d="M1658 2762 c-19 -12 -30 -30 -38 -62 -15 -67 -40 -240 -39 -280 l0
-35 27 35 c15 19 57 69 94 111 l68 78 -37 85 c-21 47 -40 86 -43 85 -3 0 -17
-8 -32 -17z"/>
<path d="M3393 2747 c12 -32 37 -165 47 -257 6 -52 12 -95 14 -97 1 -1 10 3
19 10 18 13 51 162 60 273 l5 62 -52 6 c-28 4 -62 10 -75 13 -19 5 -23 3 -18
-10z"/>
<path d="M1861 2678 c-72 -76 -72 -67 -4 -179 l47 -77 90 25 c50 14 93 26 95
28 12 12 -118 238 -151 262 -9 6 -32 -11 -77 -59z"/>
<path d="M1544 2687 c-57 -38 -56 -34 -16 -177 l27 -95 7 60 c5 33 15 100 23
149 9 49 14 90 12 92 -2 2 -25 -11 -53 -29z"/>
<path d="M2655 2638 c-33 -27 -62 -53 -64 -59 -3 -11 216 -235 224 -228 11 12
-76 323 -93 332 -4 2 -34 -18 -67 -45z"/>
<path d="M2608 2647 c-44 -19 -60 -39 -40 -51 4 -2 27 13 52 35 25 21 43 38
40 38 -3 -1 -26 -10 -52 -22z"/>
<path d="M3756 2645 c-3 -9 -5 -38 -5 -63 l1 -47 9 59 c9 60 7 81 -5 51z"/>
<path d="M1246 2585 c4 -27 12 -65 18 -83 l12 -33 23 31 23 32 -24 26 c-14 15
-33 38 -42 52 -17 25 -17 25 -10 -25z"/>
<path d="M1441 2613 l-21 -18 45 -77 c25 -42 49 -79 54 -82 4 -3 -4 39 -18 94
-14 55 -29 100 -32 100 -4 0 -16 -8 -28 -17z"/>
<path d="M2410 2570 c-58 -22 -147 -54 -197 -71 -51 -17 -93 -34 -93 -37 1 -4
11 -42 24 -85 l23 -78 154 107 c195 135 239 171 232 190 -8 21 -27 17 -143
-26z"/>
<path d="M1711 2518 c-33 -40 -75 -92 -92 -116 l-31 -43 43 7 c98 16 244 50
243 58 -3 15 -89 166 -96 166 -3 0 -34 -33 -67 -72z"/>
<path d="M3669 2534 c-30 -31 -80 -76 -111 -100 -41 -32 -58 -53 -63 -76 -4
-18 -18 -60 -30 -93 -34 -89 -39 -115 -20 -115 34 0 125 55 173 104 37 37 56
67 70 111 20 59 47 213 40 221 -2 2 -29 -22 -59 -52z"/>
<path d="M1370 2550 l-25 -20 40 -36 c42 -37 135 -107 135 -101 0 7 -112 172
-119 174 -3 2 -17 -6 -31 -17z"/>
<path d="M2475 2499 c-49 -38 -139 -102 -199 -142 l-108 -72 5 -54 c3 -29 8
-55 11 -58 22 -23 422 -27 595 -6 l54 6 -6 72 -5 72 -129 126 c-70 70 -128
127 -128 126 0 0 -40 -32 -90 -70z"/>
<path d="M3742 2510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1312 2485 c-24 -30 -28 -65 -12 -95 10 -18 81 -45 99 -38 4 2 -8 39
-28 82 l-37 79 -22 -28z"/>
<path d="M1390 2455 c0 -3 11 -28 25 -55 25 -50 26 -50 73 -49 l47 0 -29 22
c-96 72 -116 86 -116 82z"/>
<path d="M2001 2436 c-41 -13 -76 -27 -78 -32 -3 -10 112 -174 122 -174 7 0
105 61 105 65 0 24 -54 165 -63 164 -7 -1 -45 -11 -86 -23z"/>
<path d="M1727 2370 l-129 -26 63 -29 c150 -67 335 -123 351 -107 6 6 -14 44
-53 101 -47 68 -68 91 -84 90 -11 -1 -78 -14 -148 -29z"/>
<path d="M3462 2371 c-8 -5 -12 -21 -10 -37 3 -29 3 -29 15 9 6 20 10 37 10
37 -1 0 -8 -4 -15 -9z"/>
<path d="M3355 2314 c-33 -18 -113 -50 -177 -71 -65 -21 -118 -40 -118 -44 -1
-16 204 -69 272 -69 33 0 41 5 62 38 24 38 48 183 30 181 -5 -1 -36 -16 -69
-35z"/>
<path d="M1341 2299 c39 -78 101 -171 109 -162 3 4 12 27 19 49 13 39 12 44
-15 90 -15 27 -28 50 -28 51 -1 1 -25 5 -55 9 l-53 7 23 -44z"/>
<path d="M1478 2331 l-28 -6 23 -38 24 -39 15 24 c8 12 20 33 27 46 11 20 9
22 -11 21 -13 -1 -35 -4 -50 -8z"/>
<path d="M1531 2270 l-23 -41 32 -50 c18 -27 35 -48 37 -45 4 4 -16 168 -21
173 -1 2 -13 -15 -25 -37z"/>
<path d="M1718 2211 l104 -100 74 37 75 37 -78 23 c-43 12 -116 39 -163 58
-47 19 -92 38 -100 40 -8 3 32 -40 88 -95z"/>
<path d="M2840 2291 c0 -10 3 -39 6 -65 l7 -47 41 6 c23 4 49 14 58 23 16 16
13 20 -48 60 -54 35 -64 39 -64 23z"/>
<path d="M1596 2193 c7 -43 18 -93 25 -111 11 -31 14 -33 42 -28 43 9 44 12
18 54 -12 20 -36 65 -54 100 -17 34 -34 62 -38 62 -3 0 0 -35 7 -77z"/>
<path d="M1620 2267 c0 -5 70 -132 91 -164 15 -26 33 -28 66 -10 21 12 18 15
-68 95 -49 46 -89 81 -89 79z"/>
<path d="M2098 2243 c-21 -14 -38 -29 -38 -33 0 -14 25 -27 63 -34 37 -7 37
-7 37 28 0 37 -8 66 -19 65 -3 0 -23 -12 -43 -26z"/>
<path d="M3571 2180 c-24 -16 -68 -37 -97 -46 -47 -16 -53 -20 -59 -52 -3 -19
-17 -59 -30 -88 -13 -30 -21 -54 -17 -54 14 0 128 99 158 137 34 43 97 133 92
133 -2 0 -23 -14 -47 -30z"/>
<path d="M1482 2161 c-17 -55 -15 -64 17 -95 23 -22 36 -27 70 -24 l42 3 -56
78 c-31 42 -58 77 -59 77 -2 0 -8 -17 -14 -39z"/>
<path d="M2970 2182 c-9 -4 -36 -29 -60 -57 -25 -27 -49 -53 -53 -56 -4 -3
-16 -46 -26 -95 -10 -49 -31 -116 -45 -148 l-26 -59 107 6 c117 6 253 36 293
65 34 25 149 170 183 230 24 44 25 50 9 45 -31 -8 -182 23 -253 51 -65 26
-100 31 -129 18z"/>
<path d="M1918 2142 c-38 -21 -68 -40 -68 -43 0 -9 132 -99 201 -138 l56 -31
18 36 c9 20 19 53 21 73 4 31 -1 42 -38 83 -60 66 -99 70 -190 20z"/>
<path d="M2868 2163 c-13 -3 -18 -15 -18 -39 0 -19 2 -34 4 -34 2 0 19 18 36
40 33 41 29 47 -22 33z"/>
<path d="M2125 2131 c28 -31 30 -31 33 -11 4 26 4 27 -33 36 l-30 7 30 -32z"/>
<path d="M2178 2114 c-4 -33 1 -40 56 -92 68 -62 203 -165 249 -189 23 -12 35
-13 55 -4 34 16 155 108 230 176 58 53 62 59 62 101 l0 45 -87 -8 c-108 -10
-451 -10 -514 -1 l-47 7 -4 -35z"/>
<path d="M3371 2071 c-12 -22 -50 -79 -85 -126 -36 -47 -62 -85 -58 -85 4 0
25 10 47 23 44 25 85 88 110 171 19 62 13 70 -14 17z"/>
<path d="M1778 2074 c-16 -8 -28 -19 -28 -24 0 -11 102 -130 159 -184 40 -39
41 -39 80 -26 22 7 55 26 73 42 37 32 43 25 -67 90 -38 23 -93 59 -122 80 -59
43 -58 43 -95 22z"/>
<path d="M1683 2043 c-13 -2 -23 -9 -23 -15 0 -6 41 -53 92 -104 81 -84 96
-94 127 -94 l35 0 -65 68 c-36 37 -80 86 -98 110 -18 23 -36 41 -39 41 -4 -1
-17 -4 -29 -6z"/>
<path d="M2166 2038 c-2 -7 -11 -33 -20 -58 -9 -25 -16 -48 -16 -52 0 -17 315
-133 346 -126 9 2 -12 21 -51 47 -37 24 -94 67 -128 95 -33 28 -75 64 -94 79
-23 20 -34 25 -37 15z"/>
<path d="M1550 2012 c0 -7 71 -70 97 -85 6 -4 2 15 -10 43 -19 46 -24 50 -54
50 -18 0 -33 -4 -33 -8z"/>
<path d="M2745 1958 c-38 -33 -101 -81 -138 -106 l-68 -46 26 -14 c15 -7 60
-16 100 -19 l72 -6 30 64 c30 64 58 160 51 177 -1 5 -34 -18 -73 -50z"/>
<path d="M1669 1955 c12 -22 28 -47 34 -56 12 -16 77 -53 81 -47 2 2 -28 35
-67 73 -64 65 -68 67 -48 30z"/>
<path d="M2079 1874 c-13 -15 -44 -36 -68 -46 -49 -22 -50 -19 42 -82 84 -56
100 -61 174 -46 65 13 213 68 213 78 0 4 -36 18 -81 31 -44 13 -115 39 -157
58 -42 18 -82 33 -88 33 -6 0 -21 -12 -35 -26z"/>
<path d="M3210 1826 c-49 -22 -159 -106 -141 -106 34 0 205 110 191 123 -3 2
-25 -5 -50 -17z"/>
<path d="M1760 1830 c0 -13 92 -77 141 -100 54 -24 83 -26 49 -3 -14 9 -47 32
-75 52 -51 38 -115 66 -115 51z"/>
<path d="M1931 1767 c65 -49 117 -77 142 -76 21 0 21 1 4 12 -11 7 -44 31 -75
54 -36 27 -68 43 -86 43 -29 0 -29 0 15 -33z"/>
<path d="M2990 1765 c-36 -7 -109 -16 -162 -20 -54 -4 -98 -11 -98 -15 0 -12
108 -30 182 -30 63 0 75 3 124 36 30 20 54 38 54 40 0 6 -29 3 -100 -11z"/>
<path d="M2454 1760 c-15 -15 -202 -80 -231 -80 -49 0 -19 -20 75 -50 53 -18
120 -32 147 -32 64 -1 150 32 204 78 l42 37 -58 23 c-69 29 -163 41 -179 24z"/>
<path d="M2683 1743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2683 1678 c-15 -16 -50 -42 -78 -58 l-50 -30 60 0 c64 0 195 28 260
56 35 15 38 18 20 25 -11 4 -33 8 -50 8 -16 1 -53 7 -82 15 -51 14 -51 14 -80
-16z"/>
<path d="M2110 1660 c27 -18 170 -62 183 -57 7 4 6 6 -5 6 -9 1 -46 15 -83 31
-64 29 -130 43 -95 20z"/>
<path d="M1860 1315 c0 -53 1 -56 23 -53 19 3 22 9 22 53 0 44 -3 50 -22 53
-22 3 -23 0 -23 -53z"/>
<path d="M2120 1315 c0 -38 3 -45 20 -45 17 0 20 7 20 45 0 38 -3 45 -20 45
-17 0 -20 -7 -20 -45z"/>
<path d="M3290 1320 c0 -33 3 -40 20 -40 17 0 20 7 20 40 0 33 -3 40 -20 40
-17 0 -20 -7 -20 -40z"/>
<path d="M3290 1185 c0 -42 2 -46 23 -43 19 3 22 9 22 43 0 34 -3 40 -22 43
-21 3 -23 -1 -23 -43z"/>
<path d="M1268 943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1448 943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1670 925 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2550 910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2793 934 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
-16z"/>
<path d="M3190 925 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M3488 938 c-7 -19 2 -38 18 -38 8 0 14 10 14 25 0 26 -24 35 -32 13z"/>
<path d="M3605 910 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
